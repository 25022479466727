module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WSZNBGX","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"gtagConfig":{"linker":{"domains":["kushkipagos.com","kushki.cl","kushki.com","console.kushkipagos.com","uat-console.kushkipagos.com "]}},"pluginConfig":{"exclude":["/404"],"head":true},"trackingIds":["UA-128563279-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"c9kpvatv","delay_timeout":5000,"include_in_development":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#1f3664","icon":"src/content/images/icon.png","name":"Kushki","short_name":"Kushki","start_url":"/","theme_color":"#1f3664","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"409889a3df70654dc69e285bf1e791f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#023365"},
    }]
