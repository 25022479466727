// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-complaintschannel-tsx": () => import("./../../../src/pages/complaintschannel.tsx" /* webpackChunkName: "component---src-pages-complaintschannel-tsx" */),
  "component---src-pages-confirm-invite-tsx": () => import("./../../../src/pages/confirm-invite.tsx" /* webpackChunkName: "component---src-pages-confirm-invite-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-features-button-pay-tsx": () => import("./../../../src/pages/features/buttonPay.tsx" /* webpackChunkName: "component---src-pages-features-button-pay-tsx" */),
  "component---src-pages-features-cajita-tsx": () => import("./../../../src/pages/features/cajita.tsx" /* webpackChunkName: "component---src-pages-features-cajita-tsx" */),
  "component---src-pages-features-link-de-pago-smartlink-tsx": () => import("./../../../src/pages/features/link-de-pago-smartlink.tsx" /* webpackChunkName: "component---src-pages-features-link-de-pago-smartlink-tsx" */),
  "component---src-pages-features-plugins-tsx": () => import("./../../../src/pages/features/plugins.tsx" /* webpackChunkName: "component---src-pages-features-plugins-tsx" */),
  "component---src-pages-features-subscriptions-tsx": () => import("./../../../src/pages/features/subscriptions.tsx" /* webpackChunkName: "component---src-pages-features-subscriptions-tsx" */),
  "component---src-pages-form-lead-tsx": () => import("./../../../src/pages/form-lead.tsx" /* webpackChunkName: "component---src-pages-form-lead-tsx" */),
  "component---src-pages-gobcorp-tsx": () => import("./../../../src/pages/gobcorp.tsx" /* webpackChunkName: "component---src-pages-gobcorp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-education-tsx": () => import("./../../../src/pages/industries/education.tsx" /* webpackChunkName: "component---src-pages-industries-education-tsx" */),
  "component---src-pages-industries-form-tsx": () => import("./../../../src/pages/industries/form.tsx" /* webpackChunkName: "component---src-pages-industries-form-tsx" */),
  "component---src-pages-industries-psp-tsx": () => import("./../../../src/pages/industries/psp.tsx" /* webpackChunkName: "component---src-pages-industries-psp-tsx" */),
  "component---src-pages-industries-retail-tsx": () => import("./../../../src/pages/industries/retail.tsx" /* webpackChunkName: "component---src-pages-industries-retail-tsx" */),
  "component---src-pages-industries-services-tsx": () => import("./../../../src/pages/industries/services.tsx" /* webpackChunkName: "component---src-pages-industries-services-tsx" */),
  "component---src-pages-industries-tourism-tsx": () => import("./../../../src/pages/industries/tourism.tsx" /* webpackChunkName: "component---src-pages-industries-tourism-tsx" */),
  "component---src-pages-inscription-acquisition-tsx": () => import("./../../../src/pages/inscription/acquisition.tsx" /* webpackChunkName: "component---src-pages-inscription-acquisition-tsx" */),
  "component---src-pages-inscription-additional-service-psp-tsx": () => import("./../../../src/pages/inscription/additionalServicePSP.tsx" /* webpackChunkName: "component---src-pages-inscription-additional-service-psp-tsx" */),
  "component---src-pages-inscription-costa-rica-tsx": () => import("./../../../src/pages/inscription/costa-rica.tsx" /* webpackChunkName: "component---src-pages-inscription-costa-rica-tsx" */),
  "component---src-pages-inscription-guatemala-tsx": () => import("./../../../src/pages/inscription/guatemala.tsx" /* webpackChunkName: "component---src-pages-inscription-guatemala-tsx" */),
  "component---src-pages-inscription-honduras-tsx": () => import("./../../../src/pages/inscription/honduras.tsx" /* webpackChunkName: "component---src-pages-inscription-honduras-tsx" */),
  "component---src-pages-inscription-new-payment-method-tsx": () => import("./../../../src/pages/inscription/new-payment-method.tsx" /* webpackChunkName: "component---src-pages-inscription-new-payment-method-tsx" */),
  "component---src-pages-inscription-nicaragua-tsx": () => import("./../../../src/pages/inscription/nicaragua.tsx" /* webpackChunkName: "component---src-pages-inscription-nicaragua-tsx" */),
  "component---src-pages-inscription-panama-tsx": () => import("./../../../src/pages/inscription/panama.tsx" /* webpackChunkName: "component---src-pages-inscription-panama-tsx" */),
  "component---src-pages-inscription-psp-tsx": () => import("./../../../src/pages/inscription/psp.tsx" /* webpackChunkName: "component---src-pages-inscription-psp-tsx" */),
  "component---src-pages-inscription-salvador-tsx": () => import("./../../../src/pages/inscription/salvador.tsx" /* webpackChunkName: "component---src-pages-inscription-salvador-tsx" */),
  "component---src-pages-launchpad-tsx": () => import("./../../../src/pages/launchpad.tsx" /* webpackChunkName: "component---src-pages-launchpad-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-martes-de-producto-tsx": () => import("./../../../src/pages/martes-de-producto.tsx" /* webpackChunkName: "component---src-pages-martes-de-producto-tsx" */),
  "component---src-pages-operadora-tsx": () => import("./../../../src/pages/operadora.tsx" /* webpackChunkName: "component---src-pages-operadora-tsx" */),
  "component---src-pages-pagos-presenciales-tsx": () => import("./../../../src/pages/pagos-presenciales.tsx" /* webpackChunkName: "component---src-pages-pagos-presenciales-tsx" */),
  "component---src-pages-papers-and-webinars-tsx": () => import("./../../../src/pages/papers-and-webinars.tsx" /* webpackChunkName: "component---src-pages-papers-and-webinars-tsx" */),
  "component---src-pages-papers-y-webinars-tsx": () => import("./../../../src/pages/papers-y-webinars.tsx" /* webpackChunkName: "component---src-pages-papers-y-webinars-tsx" */),
  "component---src-pages-partneraws-tsx": () => import("./../../../src/pages/partneraws.tsx" /* webpackChunkName: "component---src-pages-partneraws-tsx" */),
  "component---src-pages-payments-card-tsx": () => import("./../../../src/pages/payments/card.tsx" /* webpackChunkName: "component---src-pages-payments-card-tsx" */),
  "component---src-pages-payments-cash-tsx": () => import("./../../../src/pages/payments/cash.tsx" /* webpackChunkName: "component---src-pages-payments-cash-tsx" */),
  "component---src-pages-payments-transfer-tsx": () => import("./../../../src/pages/payments/transfer.tsx" /* webpackChunkName: "component---src-pages-payments-transfer-tsx" */),
  "component---src-pages-payouts-tsx": () => import("./../../../src/pages/payouts.tsx" /* webpackChunkName: "component---src-pages-payouts-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-security-advice-tsx": () => import("./../../../src/pages/security-advice.tsx" /* webpackChunkName: "component---src-pages-security-advice-tsx" */),
  "component---src-pages-security-policy-tsx": () => import("./../../../src/pages/security-policy.tsx" /* webpackChunkName: "component---src-pages-security-policy-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-somos-tsx": () => import("./../../../src/pages/somos.tsx" /* webpackChunkName: "component---src-pages-somos-tsx" */),
  "component---src-pages-terms-and-conditions-chile-tsx": () => import("./../../../src/pages/terms-and-conditions/chile.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-chile-tsx" */),
  "component---src-pages-terms-and-conditions-colombia-tsx": () => import("./../../../src/pages/terms-and-conditions/colombia.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-colombia-tsx" */),
  "component---src-pages-terms-and-conditions-companies-tsx": () => import("./../../../src/pages/terms-and-conditions/companies.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-companies-tsx" */),
  "component---src-pages-terms-and-conditions-ecuador-tsx": () => import("./../../../src/pages/terms-and-conditions/ecuador.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-ecuador-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-terms-and-conditions-mexico-tsx": () => import("./../../../src/pages/terms-and-conditions/mexico.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mexico-tsx" */),
  "component---src-pages-terms-and-conditions-peru-tsx": () => import("./../../../src/pages/terms-and-conditions/peru.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-peru-tsx" */),
  "component---src-pages-vtex-day-tsx": () => import("./../../../src/pages/VTEXDay.tsx" /* webpackChunkName: "component---src-pages-vtex-day-tsx" */),
  "component---src-pages-work-with-us-jobs-tsx": () => import("./../../../src/pages/work-with-us-jobs.tsx" /* webpackChunkName: "component---src-pages-work-with-us-jobs-tsx" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-campaign-template-tsx": () => import("./../../../src/templates/campaignTemplate.tsx" /* webpackChunkName: "component---src-templates-campaign-template-tsx" */),
  "component---src-templates-ebook-template-tsx": () => import("./../../../src/templates/ebookTemplate.tsx" /* webpackChunkName: "component---src-templates-ebook-template-tsx" */),
  "component---src-templates-legal-document-template-tsx": () => import("./../../../src/templates/legalDocumentTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-document-template-tsx" */),
  "component---src-templates-pricing-template-tsx": () => import("./../../../src/templates/pricingTemplate.tsx" /* webpackChunkName: "component---src-templates-pricing-template-tsx" */),
  "component---src-templates-work-with-us-template-tsx": () => import("./../../../src/templates/WorkWithUsTemplate.tsx" /* webpackChunkName: "component---src-templates-work-with-us-template-tsx" */)
}

